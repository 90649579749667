import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0094 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0090/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0072/'
  )
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routeParam5 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理を効率化する方法とは？効率化をする際のポイントや具体的な方法をご紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.04.05</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0094.jpg"
          alt="キンクラコラム画像94"
        />
        <p>
          勤怠管理は、従業員の数が増えれば増えるほど時間と手間がかかります。非効率的な方法で管理をしていると、集計や給与計算の業務に追われ、担当者に過度な負担がかかります。そのため、負担を減らして業務をスピーディーに進めるためにも、勤怠管理の効率化を図ることは重要です。
          <br />
          しかし、勤怠管理を効率化しようにも具体的に何をすればいいのか分からないという方も多いでしょう。この記事では、勤怠管理を効率化させるためのポイントや、効率化に役立つシステムのメリットについて解説します。
        </p>
        <a href={routeParam} className="column-detail-link">
          関連記事：無料で使える勤怠管理アプリとは？メリットや注意点も紹介
        </a>
        <div id="toc1">
          <h1>勤怠管理の効率が悪くなってしまう原因とは</h1>
        </div>
        <img
          src="/images/column/details/c0094_g01.jpg"
          alt="キンクラコラム画像94"
        />

        <p>
          勤怠管理が非効率になっている場合、以下のような原因が考えられます。
        </p>
        <div id="toc1-1">
          <h2>アナログな勤怠管理が行われている</h2>
        </div>
        <p>
          タイムカードや紙の勤怠管理表を使ったアナログな方法は、書類を各部署から回収して集計する手間がかかる等、作業効率が大幅にダウンします。また、集計の際に電卓を使う場合は、給与計算の算出時に計算ミスが起きるリスクも増加します。それ以外にも、紙の書類を使ったアナログな勤怠管理には、テレワークなど多様な働き方に対処できないという問題点もあるでしょう。
          <br />
          テレワークや直行直帰などの柔軟な働き方が制限されることで、企業の業務効率が著しく低下する恐れがあります。
        </p>
        <a href={routeParam2} className="column-detail-link">
          関連記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？
        </a>
        <div id="toc1-2">
          <h2>労働基準法改正により勤怠管理が複雑になっている</h2>
        </div>
        <p>
          働き方改革に伴い、労働基準法は適宜改正されています。法改正に合わせて勤怠管理の方法を変更した結果、作業工数が増え複雑化してしまうケースもあります。
          <br />
          例えば、2019年の改正によって労働時間の正確な把握が義務化され、勤怠データを正確に把握できるように適切に管理することが必要になりました。それによって、従来問題のなかった管理方法を法改正に合わせて都度やり方を変えなければならず、その方法に慣れるまで効率が低下することがあります。
        </p>

        <div id="toc1-3">
          <h2>集計計算に時間がかかっている</h2>
        </div>
        <p>
          アナログな方法で勤怠管理を行う場合、従業員の勤務時間の集計はすべて手作業で行うことになります。そのため、内容に不備がある場合には個々の従業員や上司に確認し、1つずつ問題を解決していかければなりません。月末など締め日の忙しいタイミングで手作業の集計を行うと、集計担当者の作業時間が増え、ほかの作業に手が回らなくなります。
        </p>
        <a href={routeParam3} className="column-detail-link">
          関連記事：有給管理を効率化する勤怠管理システムの選び方やお勧めのシステムをご紹介
        </a>
        <p>
          このように、アナログな方法で勤怠管理を行うことで、業務や作業内容が複雑化して、結果として勤怠管理の効率が悪くなってしまいます。
        </p>

        <div id="toc2">
          <h1>勤怠管理の効率化を実現するためのポイントとは</h1>
        </div>
        <img
          src="/images/column/details/c0094_g02.jpg"
          alt="キンクラコラム画像94"
        />
        <p>
          勤怠管理を効率化させるためには、以下のようなポイントを意識しましょう。。
        </p>
        <div id="toc2-1">
          <h2>集計作業が不要になる仕組みを作る</h2>
        </div>
        <p>
          <p>
            勤怠管理業務の中でも特に手間がかかるのは、集計作業です。裏を返せば、集計作業が不要になるような仕組みを構築すれば、勤怠管理業務の効率は上がります。紙のタイムカードや書類を使った集計を廃止し、勤怠に関する情報管理を自動化できるような仕組みを作りましょう。
            <br />
            特におすすめしたいのは、勤怠管理システムの導入です。勤怠管理システムを使って情報収集や集計を自動化すれば、勤怠状況を手作業で集計する手間がなくなります。
          </p>
        </p>
        <div id="toc2-2">
          <h2>社内システムと連携させる</h2>
        </div>
        <p>
          勤怠管理システムを導入するばら、ほかの社内システムと連携させられるようなものを選びましょう。
          <br />
          生産管理システムや業務支援システム、ワークフローシステムなど、社内の複数のシステムと連携させれば、情報を1つの場所で管理できるため、生産性や業務効率が高まりやすくなります。
        </p>

        <div id="toc2-3">
          <h2>使いやすいシステムを選ぶ</h2>
        </div>
        <p>
          従業員が直感的操作できる使いやすいシステムを導入することもポイントの一つです。ただし、煩雑で使いにくいシステムを導入してしまうと、かえって効率が低下してしまう恐れがあります。
          <br />
          時間をかけて、システム導入の周知や、使用方法の研修を実施下としても、操作が難しいシステムの運用は、使用法を十分に理解できない従業員が、誤った使い方をして、その修正対応のために更に時間を割くことになるかもしれません。効率化を図るためにも、すべての従業員が快適に使えるようなシステムを選んで導入しましょう。
        </p>
        <a href={routeParam4} className="column-detail-link">
          関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <p>
          以上のポイントを押さえることで勤怠管理を効率化できます。
          <br />
          では、具体的に何をすればいいのか？次の項目で解説します。
        </p>

        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}

        <div id="toc3">
          <h1>
            勤怠管理システムを導入することで勤怠管理を効率化することが出来る
          </h1>
        </div>
        <p>
          勤怠管理システムを使った業務効率化には、以下のようなメリットがあります。
        </p>
        <div id="toc3-1">
          <h2>手作業で集計をする必要がなくなる</h2>
        </div>
        <p>
          勤怠管理システムは自動で勤務時間や休日などの集計を行います。そのため、手動で集計をする必要がなくなります。
          <br />
          勤怠管理において一番のボトルネックであった手動による集計がなくなるため、勤怠管理の効率を大幅にアップさせることが可能です。
        </p>
        <div id="toc3-2">
          <h2>法令やコンプライアンスを遵守できる</h2>
        </div>
        <p>
          法令に準拠した運用ができるのも、勤怠管理システム導入のメリットです。
          <br />
          勤怠管理の専用システムは、最新の法令に応じて自動で機能がアップデートされるので安心できます。労働基準法で定められた働き方を逸脱している場合にはアラートで知らせてくれるような勤怠管理システムもあります。法令違反や過労のリスクを早期に検知できるシステムを活用し、法令やコンプライアンスの遵守に繋げましょう。
        </p>

        <div id="toc3-3">
          <h2>人的ミスのリスクを低減できる</h2>
        </div>
        <p>
          勤怠管理システムによってはスマートフォンやパソコンから直接打刻や申請ができます。手入力の勤怠管理では、入力ミスや計算ミスが起きてしまう可能性が高いです。また、不正打刻などの問題が起こりやすいのもアナログな勤怠管理の問題点です。
          <br />
          しかし、勤怠管理システムを導入すれば、不正な打刻の防止や人的ミスの頻度を大きく下げられるといったことが可能になります。
        </p>

        <div id="toc3-4">
          <h2>従業員の状況をリアルタイムで把握できる</h2>
        </div>
        <p>
          アナログな勤怠管理を行っている場合、従業員の勤務状況を月末や締め日まで確認できないこともあります。そのため、従業員の過重労働が後になって発覚するといった問題が発生してしまう可能性もあるでしょう。
          <br />
          しかし、勤怠管理システムを導入すればいつでも最新の勤怠状況を確認できます。従業員の長時間労働が起きていないか、人員配置に無駄がないかといった分析に役立てるためにも、勤怠管理システムの導入はおすすめです。
          <br />
          <br />
          このように、勤怠管理システムを導入することでさまざまなメリットが得られます。
        </p>

        <div id="toc4">
          <h1>勤怠管理を効率化するなら「キンクラ」がおすすめ</h1>
        </div>
        <p>
          ここまでで、勤怠管理の効率が悪くなってしまう原因や、効率化する方法について解説をしてきました。勤怠管理を効率化するならば、先述したように勤怠管理システムを導入するのがおすすめです。しかし、勤怠管理システムを導入しようにも、どのようなシステムを導入すればいいのか分からないという方も多いかと思います。
          <br />
          そこでおすすめなのが「キンクラ」です。
          <br />
          「キンクラ」はクラウド型の勤怠管理システムで、様々な働き方に対応できます。また、全機能が無料でお試しできるトライアルがあるため、実際に導入をしてみて、使用感を確かめることが出来ます。
          <br />
          もし、勤怠管理システムの導入を検討しているならば「キンクラ」を試してみませんか？
        </p>

        <a href={routeParam5} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0094
